.button {
    display: flex;
    width: 100%;
    height: 44px;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 49px;
    background: linear-gradient(58deg, #7367F0 0%, #9D93FF 100%);
    box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.20) inset;
    color: white;
    font-weight: 600;
    text-align: center;
    font-feature-settings: 'clig'off, 'liga'off;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    &:hover {
        box-shadow: none;
    }

    &:active,
    &.active {
        box-shadow: none;
        background: linear-gradient(45deg, #4839EB 0%, #7367F0 100%);
    }

    &:disabled,
    &.disabled {
        background: #B2ABF7;
        box-shadow: 0 -3px 0 0 rgba(0, 0, 0, 0.20) inset;
    }

    &.capitalize {
        text-transform: capitalize;
    }

    &.flat-rounded {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 10px 22px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 49px;
        background: white;
        box-shadow: none;
        color: #7367F0;
        font-weight: 600;
        text-align: center;
        font-feature-settings: 'clig'off, 'liga'off;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        margin-top: 1px;
    
        &:hover {
            background: rgba(115, 103, 240, 0.04);
        }
    
        &:active,
        &.active {
            background: rgba(115, 103, 240, 0.2);
        }
    
        &:disabled,
        &.disabled {
            color: rgba(115, 103, 240, 0.65);
        }
    }

    &.link-button {
        width: auto;
        background: none;
        padding: 0;
        box-shadow: none;
        text-transform: capitalize;
        color: #393939;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        cursor: pointer;
    }

    &.outline-rounded {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 10px 22px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 49px;
        border: 2px solid #7367F0;
        background: transparent;
        color: #7367F0;
        font-weight: 600;
        text-align: center;
        font-feature-settings: 'clig'off, 'liga'off;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.4px;
        box-shadow: none;
    
        &:hover {
            background: rgba(115, 103, 240, 0.04);
        }
    
        &:active,
        &.active {
            background: rgba(115, 103, 240, 0.2);
        }
    
        &:disabled,
        &.disabled {
            color: rgba(115, 103, 240, 0.65);
            border: 2px solid rgba(115, 103, 240, 0.65);
        }
    }

    &.with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    &.default {
        width: auto;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        color: #FFF;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
        background-color: rgba(115, 103, 240, 1);
        border-radius: 5px;

        &:hover {
            box-shadow: 0 0 10px 0 rgba(115, 103, 240, 0.65);
        }
        
        &:active {
            background-color: #5E50EE;
        }
        
        &:focus {
            background-color: #5E50EE;
        }
        
        &:disabled {
            background-color: rgba(115, 103, 240, 0.65);
        }
    }

    &.light {
        width: auto;
        height: 38px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        color: rgba(115, 103, 240, 1);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
        background: #EEEDFD;
        border: none;
        box-shadow: none;
        border-radius: 5px;
    }

    &.outline {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        color: rgba(115, 103, 240, 1);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.4px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid rgba(115, 103, 240, 1);
        background: transparent;
        box-shadow: none;

        &:hover {
            background: rgba(115, 103, 240, 0.04);
        }

        &:active {
            background: rgba(115, 103, 240, 0.2);
        }

        &:focus {
            background: rgba(115, 103, 240, 0.2);
        }

        &:disabled {
            color: rgba(115, 103, 240, 0.65);
            border: 2px solid rgba(115, 103, 240, 0.65);
        }
    }    
}
