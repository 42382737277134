.side_bar {
    width: 222px;
    height: 100%;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    margin-right: 24px;
    position: fixed;
    z-index: 100;
    overflow: hidden;
    transition: width 0.3s ease;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.side_bar_close {
    width: 82px !important;
}

.side_bar_logo_block {
    margin: 13px 13px 19px 31px;
    display: flex;
    justify-content: space-between;
}

.side_bar_logo {
    width: 145px;
    height: 28px;

    img {
        width: 100%;
        height: 100%;
    }
}

.side_bar_logo_small {
    width: 43px;
    height: 28px;
    margin-left: -10px;

    img {
        width: 100%;
        height: 100%;
    }
}

.side_bar_arrow {
    margin-top: 3px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: transform 0.3s ease;

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;

        svg {
            fill: #6E6B7B;
            width: 8px;
            height: 14px;
        }
    }
}

.side_bar_menu_block {
    margin: 19px 0;

    h3 {
        color: #B9B9C3;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 16px;
        text-align: center;

        &.text_menu_name {
            color: #6E6B7B;
            font-size: 14px;
            text-transform: capitalize;
        }
    }

    ul{
        li {
            a,
            .menu-link {
                display: flex;
                height: 42px;
                padding: 8px 30px;
                background-color: transparent;
                cursor: pointer;
        
                @media (hover: hover) {
                    &:hover {
                        background-color: rgba(158, 149, 245, 0.2);
                        border-radius: 5px;
                    }
                }
        
                .image {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
        
                span {
                    color: #393939;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: -0.6px;
                    margin-left: 16px;
                    white-space: nowrap;
                }
            }
            &.active {
                & > a {
                    border-radius: 5px;
                    background: linear-gradient(58deg, #7367F0 0%, #9D93FF 100%);
                    box-shadow: 0 0 4px 0 #7367F0;
                    
                    & > span {
                        color: white !important;
                    }
                }
            }
        }
    }

}

.text_menu_close {
    padding: 0 !important;
    width: 100%;
    text-align: center;
}

.chats {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease;
}

.sub-menu {
    max-height: 1000px;
}

.sub_menu {
    a {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 5px;

        span {
            flex-grow: 1;
        }

        .badge {
            width: 18px;
            height: 18px;
            border-radius: 18px;
            background-color: #DE42A8;
            color: white;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
        }

        .name {
            margin: 0;
        }

        .avatar {
            margin-right: 8px;
        }
    }
}