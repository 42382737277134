.auth_main {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.auth {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    background: url('../images/login.svg') no-repeat 0 50%;
    background-size: 50%;

    @media screen and (max-width: 1024px) {
        background: none;
    }
}

.auth_image {
    flex-grow: 1;

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.auth_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auth_logo {
    width: 226px;
    height: 44px;
    max-width: 226px;
    max-height: 44px;
}

.auth_logo img {
    width: 100%;
    height: 100%;
}

.auth_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 532px;
    padding: 93px 72px;
    background-color: white;

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100vh;
        padding: 27px 16px;
    }

    @media (orientation: landscape) and (max-width: 1024px) {
        height: 100%;
    }

}

.auth_agreement {
    width: 100%;
    color: #BABFC7;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    padding-top: 16px;
    border-top: 1px solid #D8D6DE;
    margin-top: 32px;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }
}

.auth_terms_block {
    margin-top: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3px;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }
}

.auth_terms_block a {
    color: #7367F0;
    font-size: 12px;
    font-weight: 400;
    text-decoration-line: underline;
}

.auth_terms_block span {
    color: #BABFC7;
    font-size: 12px;
    font-weight: 400;
}
