.auth_form {
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
        margin: 0 auto;
    }

    form {
        width: 100%;
    }

    h1 {
        margin: 0 0 56px;
        color: #393939;
        font-size: 32px;
        font-weight: 700;
        line-height: 52px;
        width: 100%;
        text-align: center;
    
        @media screen and (max-width: 1024px) {
            max-width: 388px;
        }

        @media screen and (max-width: 744px) {
            margin: 32px 0;
        }
    }

    button {
        margin-top: 12px;
    
        @media screen and (max-width: 1024px) {
            max-width: 388px;
        }
    }
}



.auth_input {
    position: relative;
    color: #393939;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 16px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }

    .input-show-pass-btn {
        position: absolute;
        font-size: 16px;
        bottom: 11px;
        right: 15px;
        background: none;
        border: none;
        padding: 0px;
        cursor: pointer;
        margin: 0;

        svg {
            position: static;
            width: 17px;
            color: #393939;
        }
    }
}

.auth_input input {
    border-radius: 5px;
    background: #FFF;
    border: 1px solid #D8D6DE;
    padding: 8px 15px;
    color: #6E6B7B;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.auth_input input::placeholder {
    color: #BABFC7;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}

.forgot_password {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 12px;
    color: #6E6B7B;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 4px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }
}

.auth_or {
    margin-top: 16px;
    color: #999;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.google {
    display: flex;
    height: 38px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 38px;
    border: 1px solid #BABFC7;
    background: #FFF;
    width: 100%;
    margin-top: 12px;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }
}

.google img {
    width: 20px;
    height: 20px;
}

.google span {
    color: #82868B;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-transform: capitalize;
}

.dont_have {
    margin-top: 44px;
    color: #82868B;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    width: 100%;

    @media screen and (max-width: 1024px) {
        max-width: 388px;
    }
}

.error {
    color: #EA5455;
    margin-top: 8px;
}