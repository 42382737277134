.mobile_header_menu {
    display: none;
    height: 61px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 18px 13px;
    z-index: 2;

    @media screen and (max-width: 1024px) {
        display: block;
        position: fixed;
        z-index: 997;
        width: 100%;
        bottom: 0;
    }

    @media screen and (max-width: 744px) {
        height: 57px;
        padding: 8px 17px 10px;
    }
}
.mobile_header_menu_buttons {
    display: flex;
    
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 744px) {
        justify-content: space-around;
    }

    .menu_active span,
    .menu_active svg {
        color: #7367F0;
        fill: #7367F0;
    }

    .image {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    span {
        color: #393939;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: -0.6px;
        text-transform: capitalize;
        font-family: 'Sora';
    
        @media screen and (max-width: 744px) {
            font-size: 12px;
            font-weight: 300;
            line-height: 18px;
        }
    }
}

.nav_bar {
    height: 61px;
    box-sizing: border-box;
    display: flex;
    
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    padding: 19px 24px;
    margin-left: 24px;
    position: relative;

    @media screen and (max-width: 1024px) {
        background-color: transparent;
        box-shadow: none;
        margin-left: 0;
        margin-top: 8px;
    }

    @media screen and (max-width: 744px) {
        padding: 19px 16px;
        justify-content: space-between;
    }

    .burger-menu {
        display: none;
    
        @media screen and (max-width: 1024px) {
            display: block;
        }
    }

    .image {
        width: 24px;
        height: 24px;
    }
}

.nav_center_buttons {
    // margin-left: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 744px) {
        margin-left: 0;
        width: auto;
    }

    ul {
        gap: 24px;
        display: flex;
        
        align-items: center;
        justify-content: center;
        width: 100%;
    
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    a {
        color: #393939;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.56px;
        text-transform: capitalize;
    }
}

.mobile_logo {
    width: 145px;
    height: 28px;
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.nav_right_buttons {
    width: 164px;
    display: flex;
    
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 744px) {
        width: auto;
        justify-content: flex-end;
    }

    .account_menu_button {
        @media screen and (max-width: 744px) {
            margin: 0;
        }
    }
}

.account_menu {
    position: absolute;
    filter: drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.10));
    background-color: white;
    top: 35px;
    right: 12px;
    z-index: 999;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .link {
        width: 100%;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            background-color: white;
            padding: 16px;
            width: 100%;

            &:hover {
                background-color: #EEEDFD;
            }
        }

        svg {
            font-size: 24px;
        }

        span {
            color: #393939;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            text-transform: capitalize;
        }
    }
}

.nav_right_buttons_search {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    cursor: pointer;
    z-index: 4;

    @media screen and (max-width: 744px) {
        margin-right: 15px;
    }
}

.nav_search_input {
    width: 300px;
    position: absolute;
    z-index: 2;
    margin-right: 400px;
    height: 36px;
    border-radius: 6px;
    padding: 18px 36px 18px 18px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
    transition: opacity 0.3s;
    opacity: 1;
    background-color: white;

    @media screen and (max-width: 744px) {
        margin-right: 275px;
        width: 250px;
    }
}

.nav_right_buttons_search img {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
}
.nav_right_buttons_lang_wrapper {
    height: 24px;
    width: 57px;
    display: flex;
    
    margin-right: 15px;
    position: relative;

    @media screen and (max-width: 744px) {
        display: none;
    }
}
.mobile_lang {
    display: none;
    position: relative;

    @media screen and (max-width: 744px) {
        display: flex !important;
        margin-left: 100px;
    }

    .image {
        right: 0 !important;
    }
}

.nav_right_buttons_lang_wrapper{
    .image {
        width: 24px;
        height: 24px;
        background-color: transparent;
        position: absolute;
        right: 0;
        z-index: 1;
    }

    svg {
        width: 24px;
        height: 24px;
        background-color: transparent;
    }
}

.nav_right_buttons_lang {
    height: 100%;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
    color: #393939;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    text-transform: capitalize;
    cursor: pointer;
    padding-right: 15px;
    z-index: 2;

    span {
        color: #393939;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        text-transform: capitalize;
        margin-right: 15px;
    }

    .image {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.nav_right_buttons_lang::-ms-expand {
    display: none;
}

.nav_right_buttons_login {
    color: #7367F0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    text-transform: capitalize;
    width: 48px;
}

/* end navbar */


/* mobile menu */

.mobile_menu {
    display: none;

    @media screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 298px;
        height: 100vh;
        background-color: white;
        z-index: 999;
        box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.12);
        padding: 25px 30px 90px;
        transition: left 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    ul {
        margin-top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex-grow: 1;
    }

    a{
        color: #393939;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.56px;
    }
}