* {
    box-sizing: border-box;

    &::after,
    &::before {
        box-sizing: border-box;
    }

    &:focus {
        outline: 0 none;
    }

    &:not(html):not(body):not([data-disabled-custome-scroll="true"]) {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        // &::-webkit-scrollbar-thumb {
        //     background: $colors-night-rider;
        // }
    }
}

body {
    font-family: 'Sora', sans-serif;
    background: #F4FAFF;
}

.container {
    margin-left: 222px;
    transition: margin-left 0.3s ease;

    @media screen and (max-width: 1024px) {
        margin-left: 0;
    }
}

.main-container {
    position: relative;
    top: 24px;
    margin: 0 24px;
    border-radius: 6px;
    border: 1px solid #EBE9F1;
    height: calc(100vh - 24px);
    overflow: hidden;
    background-color: #fff;
    padding: 24px;
    
    @media screen and (max-width: 768px) {
        height: calc(100vh - 85px);
    }
}

.container-full-wide {
    margin-left: 82px !important;

    @media screen and (max-width: 1024px) {
        margin-left: 0 !important;
    }
}

.mobile_display_none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.display-none {
    display: none !important;
}

.tablet-display-none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.filter-display-none {
    @media screen and (max-width: 1024px) {
        left: -300px;
    }
}
.background-display-none {
    @media screen and (max-width: 1024px) {
        display: none !important;
    }
}

.overlay {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(75,70,92,.5);
        z-index: 998;
        transition: all .25s ease;
        content: '';
    }
}

.row {
    display: flex;
    flex-direction: row;
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid white;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14));
    background-color: rgba(115, 103, 240, 1);
    margin-right: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.56px;
        text-transform: capitalize;
        text-align: center;
        padding-top: 2px;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.checkbox-row {
    position: relative;
    display: flex;
    align-items: center;

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;        
    }

    label {
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:before {
            content: '';
            display: block;
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 2px solid rgba(186, 191, 199, 1);
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    input:checked + label:before,
    input.checkbox_checked + label:before {
        content: '';
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        background-color: #7367F0;
        filter: drop-shadow(0px 2px 4px #7367F0);
        overflow: hidden;
        border: 0 !important;
    }

    input:checked + label:after,
    input.checkbox_checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 4px;
        width: 10px;
        height: 5px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        rotate: -45deg;
    }
}


// .checkbox_input:before {
//     content: '';
//     display: block;
//     position: relative;
//     bottom: 15px;
//     width: 18px;
//     height: 18px;
//     border-radius: 4px;
//     border: 2px solid rgba(186, 191, 199, 1);
//     overflow: hidden;
//     box-sizing: border-box;
// }



// .checkbox_checked:after {
//     content: '';
//     display: block;
//     position: relative;
//     bottom: 28px;
//     left: 4px;
//     width: 10px;
//     height: 5px;
//     border-bottom: 2px solid white;
//     border-left: 2px solid white;
//     rotate: -45deg;
// }