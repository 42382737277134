.balance-page{
    .balance-wrapper {
        position: relative;
        margin: 0 24px;
        border-radius: 6px;
        border: 1px solid #EBE9F1;
        height: calc(100vh - 24px);
        overflow: hidden;
        background-color: #fff;

        @media screen and (max-width: 1024px) {
            height: calc(100vh - 61px);
            margin: 0;
        }

        @media screen and (max-width: 744px) {
            height: calc(100vh - 57px);
        }

        .title {
            padding: 15px 24px;
            border-bottom: 1px solid #EBE9F1;

            p {
                font-family: 'Sora';
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px; /* 150% */
            }
        }

        .balance-info {
            display: flex;
            align-items: flex-start;
            gap: 100px;
            margin-top: 25px;
            padding: 0 24px;

            h5 {
                color: #BABFC7;
                font-family: 'Sora';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px; /* 178.571% */
                letter-spacing: 0.56px;
                text-transform: capitalize;
            }

            p {
                font-family: 'Sora';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 16px;
            } 
        }

        .withdrawal-money {
            margin-top: 44px;

            form {
                padding: 16px 24px;
            }

            .label {
                color: #393939;
                font-family: 'Sora';
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 150% */
                margin-top: 8px;

                &.error {
                    color: #EA5455;
                }
            }

            input {
                max-width: 444px;
                width: 100%;
                padding: 8px 15px;
                margin-top: 2px;
                border-radius: 5px;
                border: 1px solid #D8D6DE;
                background: #FFF;
                font-family: 'Sora';
                color: #6E6B7B;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                
                &::placeholder {
                    color: #BABFC7;
                }

                &.error {
                    border: 1px solid #EA5455;
                }
            }

            .error-msg {
                font-size: 12px;
                color: #EA5455;
            }

            .withdrawal-btn {
                max-width: 240px;
                margin-top: 24px;
            }
        }

        .history {

          
          .status {
            
            .success {
              color: #28C76F;
            }
  
            .failed {
              color: #EA5455;
            }

            svg {
              font-size: 24px;
            }
          }

            table {
                border: 1px solid #ccc;
                border-collapse: collapse;
                margin: 0;
                padding: 0;
                width: calc(100% - 48px);
                table-layout: fixed;
                margin: 24px 24px 0;
              }
              
              table caption {
                font-size: 1.5em;
                margin: .5em 0 .75em;
              }
              
              table tr {
                // background-color: #f8f8f8;
                border: 1px solid #ddd;
                padding: .35em;
              }
              
              table th,
              table td {
                padding: .625em;
                text-align: left;
              }
              
              table th {
                font-size: .85em;
                letter-spacing: .1em;
                text-transform: uppercase;
              }
              
              @media screen and (max-width: 600px) {
                table {
                  border: 0;
                }
              
                table caption {
                  font-size: 1.3em;
                }
                
                table thead {
                  border: none;
                  clip: rect(0 0 0 0);
                  height: 1px;
                  margin: -1px;
                  overflow: hidden;
                  padding: 0;
                  position: absolute;
                  width: 1px;
                }
                
                table tr {
                  border-bottom: 3px solid #ddd;
                  display: block;
                  margin-bottom: .625em;
                }
                
                table td {
                  border-bottom: 1px solid #ddd;
                  display: block;
                  font-size: .8em;
                  text-align: right;
                }
                
                table td::before {
                  /*
                  * aria-label has no advantage, it won't be read inside a table
                  content: attr(aria-label);
                  */
                  content: attr(data-label);
                  float: left;
                  font-weight: bold;
                  text-transform: uppercase;
                }
                
                table td:last-child {
                  border-bottom: 0;
                }
              }
        }
    }
}