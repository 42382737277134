.client-counts {
    display: block;
    margin-top: 40px;
}

.video-block {
    max-width: 500px;
    margin: 0 auto;
    button {
        margin-top: 40px;
    }
}